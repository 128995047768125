<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('代理返佣')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="">
            <div class="inc-tabs">
            <van-tabs v-model="active" class="ui-tabs" @change="onChange">
                <van-tab swipe-threshold="3">
                    <template #title>
                        <div class="tm-tab-title">{{$t('一级代理')}}</div>
                    </template>
                </van-tab>
                <van-tab>
                    <template #title>
                        <div class="tm-tab-title">{{$t('二级代理')}}</div>
                    </template>
                </van-tab>
                <van-tab>
                    <template #title>
                        <div class="tm-tab-title">{{$t('三级代理')}}</div>
                    </template>
                </van-tab>
            </van-tabs>
            </div>
            <van-pull-refresh
                    :loosing-text="$t('释放即可刷新')"
                    :pulling-text="$t('下拉即可刷新')"
                    :loading-text="$t('加载中')"
                    v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                >
                    <div class="agent-cells">
                        <div class="agent-card" v-for="(item, index) in list" :key="index">
                            <div class="agent-card_bd flex-btw">
                                <div class="kna-info">
                                    <div class="text text-gray">{{$t('金额')}}</div>
                                    <div class="val text-green">+{{parseFloat(item.money).toFixed(6)}}</div>
                                </div>

                                <div class="kna-info" style="max-width: 50%">
                                    <div class="text text-gray">{{$t('奖励')}}</div>
                                    <div class="val text-green" >{{$t(item.title)}}</div>
                                </div>
                            </div>
                            <div class="kna-info">
                                <div class="text-gray">{{item.created_at}}</div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                active: '',
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                page: 0,
                size: 20,
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            onChange(index) {
                this.list = [];
                this.page = 0;
                this.onLoad();
            },
            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                this.$axios
                    .get(this.$api_url.income_list + '?page=' + this.page + '&level=' + (parseInt(this.active) + 1))
                    .then((res) => {
                        this.page = this.page + 1;
                        this.list = this.list.concat(res.data.data);
                        if (res.data.data.length < this.size) {
                            this.finished = true;
                        }
                        this.loading = false;
                    });
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.page = 0;
                this.onLoad();
            },
        }
    }
</script>
<style scoped="scoped">
    .agent-cells{
        padding: 15px;
        font-size: 14px;
    }
    .page ::v-deep .van-nav-bar{
        background-color: #1a2331;
    }
    .kna-info{
        padding: 5px;
    }
</style>